import React from "react"
import "./style.scss"
import { RichText } from "prismic-reactjs"

export default function content({ data }) {
  return (
    <div className="termite-exp-carousel-content">
      <div className="termite-exp-carousel-content-para">
        <h2>EXPERIENCE</h2>
        <RichText render={data.description} />
        {data.description.length === 1 ? (
          <>
            <br />
            <br />
            <br />
          </>
        ) : null}
      </div>
      <div className="carousel-image">
        {data.image ? <img src={data.image.url} alt="" /> : null}
      </div>
    </div>
  )
}
